var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container pt-xs-1 pt-3 pb-5" }, [
    _c("div", { staticClass: "text-center" }, [
      _c("h5", { staticClass: "sub-title" }, [
        _vm._v(_vm._s(_vm.$t("subtitles.results_page")))
      ])
    ]),
    _vm._v(" "),
    _vm.hasExamResult
      ? _c("div", [
          _c("div", { staticClass: "text-center mt-3" }, [
            _c("div", { staticClass: "subtitle-1" }, [
              _vm._v(_vm._s(_vm.examResult.record.name))
            ]),
            _vm._v(" "),
            _vm.examResult.record && _vm.examResult.record.mother
              ? _c("div", { staticClass: "subtitle-1" }, [
                  _vm._v("Mãe: " + _vm._s(_vm.examResult.record.mother))
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.examResult.record && _vm.examResult.record.father
              ? _c("div", { staticClass: "subtitle-1" }, [
                  _vm._v("Pai: " + _vm._s(_vm.examResult.record.father))
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "d-block subtitle-1" }, [
              _vm._v(
                _vm._s(_vm.formattedDatetime(_vm.examResult.record.birthdate))
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "container" }, [
            _c("div", { staticClass: "d-flex justify-content-center mt-4" }, [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-column justify-content-center align-items-center"
                },
                [
                  _c("div", { staticClass: "icon-wrapper mt-5 mr-4" }, [
                    _c("img", {
                      staticClass: "icon-result",
                      attrs: {
                        src: _vm.parseEarResultToImage(
                          _vm.examResult.result_left
                        ),
                        alt: ""
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "icon-result-wrapper icon-result-left" },
                    [
                      _c("span", { staticClass: "icon-result-label" }, [
                        _vm._v("Esquerda")
                      ]),
                      _vm._v(" "),
                      _vm.examResult.result_left.id !== 5
                        ? _c(
                            "span",
                            { staticClass: "icon-result-label-small" },
                            [
                              _vm._v(
                                "(" +
                                  _vm._s(_vm.examResult.result_left.name) +
                                  ")"
                              )
                            ]
                          )
                        : _vm._e()
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _vm._m(0),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-column justify-content-center align-items-center"
                },
                [
                  _c("div", { staticClass: "icon-wrapper mt-5 ml-4" }, [
                    _c("img", {
                      staticClass: "icon-result",
                      attrs: {
                        src: _vm.parseEarResultToImage(
                          _vm.examResult.result_right
                        ),
                        alt: ""
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "icon-result-wrapper icon-result-right",
                      staticStyle: { "margin-left": "1.7rem" }
                    },
                    [
                      _c("span", { staticClass: "icon-result-label" }, [
                        _vm._v("Direita")
                      ]),
                      _vm._v(" "),
                      _vm.examResult.result_right.id !== 5
                        ? _c(
                            "span",
                            { staticClass: "icon-result-label-small" },
                            [
                              _vm._v(
                                "(" +
                                  _vm._s(_vm.examResult.result_right.name) +
                                  ")"
                              )
                            ]
                          )
                        : _vm._e()
                    ]
                  )
                ]
              )
            ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "d-flex flex-column justify-content-xs-center justify-content-sm-start align-items-center mt-4"
            },
            [
              _c("div", [
                _c(
                  "span",
                  {
                    staticClass: "icon-result-label",
                    staticStyle: {
                      "font-weight": "normal",
                      "font-size": "1rem"
                    }
                  },
                  [
                    _vm._v(
                      "Data do teste: " +
                        _vm._s(
                          _vm.formattedDatetime(_vm.examResult.performed_at)
                        )
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              !_vm.hasPassedExam || _vm.examResult.record.risk_factor
                ? _c("div", { staticClass: "sub-title mt-2" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex flex-column justify-content-center align-items-center",
                        staticStyle: { color: "#97999b" }
                      },
                      [
                        _vm.examResult.record.risk_factor
                          ? _c("span", [
                              _vm._v(
                                "\n            Vai ser contactado para consulta de Otorrinolaringologia, fator de risco.\n          "
                              )
                            ])
                          : !_vm.hasPassedExam
                          ? _c("span", [
                              _vm._v(
                                "Se até aos 3 meses não for chamado deve dirigir-se ao serviço de ORL do Hospital onde realizou o RANU"
                              )
                            ])
                          : _vm._e()
                      ]
                    )
                  ])
                : _vm._e()
            ]
          )
        ])
      : _c("div", { attrs: { clasS: "text-center" } }, [
          _vm._m(1),
          _vm._v(" "),
          _c("p", { staticClass: "py-3" }, [
            _vm._v(_vm._s(_vm.$t("messages.exam_results_not_ready")))
          ])
        ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", {
        staticClass: "main-icon",
        attrs: { src: require("@/images/newborn.svg"), alt: "" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", {
        staticClass: "main-icon",
        attrs: { src: require("@/images/newborn.svg"), alt: "" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }