const get = (key, isJson = true) => {
  let item = localStorage.getItem(key)
  if (item !== null && isJson) {
    item = JSON.parse(item)
  }

  return item
}

const set = (key, item, isJson = true) => {
  if (isJson) {
    item = JSON.stringify(item)
  }
  localStorage.setItem(key, item)
}

const remove = (key) => {
  localStorage.removeItem(key)
}

const hasKey = (key) => {
  return localStorage.hasOwnProperty(key)
}

const clear = () => {
  Object.keys(localStorage).forEach(key => remove(key))
}

export default {
  get,
  set,
  remove,
  clear,
  hasKey
}
