var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("navbar", {
        attrs: { isLoggedIn: _vm.isLoggedIn },
        on: { signOut: _vm.onLogout }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "container pt-xs-1 pt-3" }, [
        _c(
          "div",
          { staticClass: "content mt-5" },
          [
            _c(
              "fade-transition",
              { attrs: { duration: 200, mode: "out-in" } },
              [
                _vm.currentPage === _vm.pages.CHOOSE_AUTHORIZATION_SIGNUP
                  ? _c("choose-signin-signup", {
                      on: {
                        "first-access": _vm.onFirstAccess,
                        "have-an-account-already": _vm.onHaveAnAccountAlready
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.currentPage === _vm.pages.SIGNUP_RECOVER_PAGE1
                  ? _c("signup-recover-page1", {
                      attrs: { routeParams: _vm.routeParams },
                      on: {
                        signup_completed: _vm.onSignupCompleted,
                        "go-back": function($event) {
                          return _vm.onGoBack(_vm.pages.SIGNUP_RECOVER_PAGE1)
                        }
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.currentPage === _vm.pages.SIGNUP_RECOVER_PAGE2
                  ? _c("signup-recover-page2", {
                      attrs: { record: _vm.record },
                      on: {
                        access_codes_verified: _vm.onAuthorizationCompleted,
                        cancel: function($event) {
                          return _vm.onGoBack(_vm.pages.SIGNUP_RECOVER_PAGE2)
                        }
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.currentPage === _vm.pages.AUTHORIZATION_PAGE
                  ? _c("authorization", {
                      attrs: { routeParams: _vm.routeParams },
                      on: {
                        "on-code-sent": _vm.onCodeSent,
                        "on-recover-account": _vm.onRecoverAccount
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.currentPage === _vm.pages.AUTHORIZATION_CODE_PAGE
                  ? _c("authorization-code", {
                      attrs: {
                        record: _vm.record,
                        "access-code": _vm.accessCodes[0]
                      },
                      on: {
                        "go-back": function($event) {
                          return _vm.onGoBack()
                        },
                        "authorization-completed": _vm.onAuthorizationCompleted
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.currentPage === _vm.pages.RESULTS_PAGE
                  ? _c("results-page", {
                      attrs: { examResult: _vm.examResult }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.currentPage === _vm.pages.UPDATE_CONTACTS
                  ? _c("update-contacts-page", {
                      attrs: {
                        accessAttempt: _vm.accessAttempt,
                        accessCode: _vm.accessCode
                      },
                      on: { "contacts-updated": _vm.onContactsUpdated }
                    })
                  : _vm._e()
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }