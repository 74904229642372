<template>
  <div>
    <!--    Header-->
    <div class="text-center">
      <h2 class="title title-primary-color">{{ $t('titles.project_title') }}</h2>
    </div>

    <!-- Form -->
    <form v-on:submit.prevent="submit" class="d-flex flex-column align-items-center">
      <div class="col-12 col-md-8 col-lg-6">
        <div class="form-group mt-3 has-search">
          <span class="form-control-feedback">
            <i class="input-icon fas fa-calendar-alt"></i>
          </span>
          <TheMask
              id="birthdate"
              type="text"
              v-model="form.data.birthdate"
              :masked="true"
              class="form-control round-input"
              mask="##/##/####"
              pattern="[0-9]{2}/[0-9]{2}/[0-9]{4}"
              placeholder="Data de Nascimento (dd-mm-aaaa)"
              :class="{'is-invalid': form.errors.has('birthdate')}"
              @input="form.errors.clear('birthdate')"
              required
          />
          <div class="invalid-feedback" v-if="form.errors.has('birthdate')">
            {{ $t(form.errors.get('birthdate')) }}
          </div>
        </div>
      </div>

      <div class="col-12 col-md-8 col-lg-6 mt-2">
        <div class="form-group has-search">
          <span class="form-control-feedback">
            <i class="input-icon far fa-address-card"></i>
          </span>
          <input
              v-model="form.data.process_id"
              type="text"
              class="form-control round-input"
              :placeholder="$t('forms.placeholders.process_id')"
              :class="{'is-invalid': form.errors.has('process_id')}"
              @focus="form.errors.clear('process_id')"
              required
          >

          <div class="invalid-feedback" v-show="form.errors.has('process_id')">
            {{ $t(form.errors.get('process_id')) }}
          </div>
        </div>
      </div>

      <div class="col-12 col-md-8 col-lg-6 mt-2">
        <div class="form-group has-search">
          <span class="form-control-feedback">
            <i class="input-icon far fa-address-book"></i>
          </span>
          <input
              v-model="form.data.contact"
              type="text"
              class="form-control round-input"
              :placeholder="$t('forms.placeholders.contact')"
              :class="{'is-invalid': form.errors.has('contact')}"
              @focus="form.errors.clear('contact')"
              required
          >

          <div class="invalid-feedback" v-show="form.errors.has('contact')">
            {{ $t(form.errors.get('contact')) }}
          </div>
        </div>
      </div>

      <div class="col-12 col-md-8 col-lg-6">
        <p class="left">
          {{ $t('messages.recover_access') }}
          <a
              class="button-text"
              :class="{disabled: isLoading}"
              @click="onRecoverAccount">
            {{ $t('messages.click_here') }}
          </a>
        </p>
      </div>

      <div class="col-12 col-md-8 col-lg-6 contents">
        <button
            class="button-rounded button-green"
            type="button"
            :disabled="isLoading"
            @click="onSendCode">
          {{ $t('buttons.send_validation_code') }}
        </button>
      </div>

      <div v-if="isLoading">
        <loading-animation></loading-animation>
      </div>
    </form>
  </div>
</template>

<script>
import Errors from '@/utils/StandardErrors.js'
import {ExternalAccessService} from '@/api/RanuService';
import {TheMask} from 'vue-the-mask';

export default {
  components: {
    TheMask
  },
  props: {
    routeParams: {
      type: Object,
      required: true
    },
  },
  mounted: function () {
    if (this.routeParams.process_id) {
      this.form.data.process_id = this.routeParams.process_id;
    }
  },
  data() {
    return {
      form: {
        data: {
          process_id: null,
          birthdate: null,
          contact: null,
        },
        errors: new Errors()
      },
      isLoading: false,
    }
  },
  methods: {
    onRecoverAccount: function () {
      if (this.isLoading) {
        return;
      }
      this.$emit('on-recover-account');
    },
    onSendCode: function () {
      this.isLoading = true;
      ExternalAccessService.authorization(this.form.data)
          .then(response => {
            if (!response.record || !response.access_code) {
              return;
            }
            this.$emit('on-code-sent', response);
          })
          .catch(response => {
            const error = response.response;
            if (error && (error.status === 422 || error.data || error.data.errors)) {
              this.form.errors.record(error.data.errors || error.data || error, 'errors.')
            } else if (error && (error.status === 403 || error.data || error.data.errors)) {
              this.form.errors.record(error.data.errors || error.data || error, 'errors.')
            }
          })
          .finally(() => {
            this.isLoading = false
          })
    }
  }
}
</script>
<style scoped lang="scss">
.left {
  float: right;
}

.contents {
  display: contents;
}

.button-rounded {
  font-size: x-large;
  height: 4rem;
  border-radius: 2rem;
}

.button-text.disabled {
  cursor: default;
}
</style>
