var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.type,
    _vm._g(
      _vm._b(
        {
          tag: "component",
          attrs: {
            tag: _vm.tag,
            "enter-active-class": "fadeIn",
            "leave-active-class": "fadeOut",
            "move-class": "fade-move"
          }
        },
        "component",
        _vm.$attrs,
        false
      ),
      _vm.hooks
    ),
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }