var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("nav", { staticClass: "navbar navbar-light d-flex" }, [
    _vm._m(0),
    _vm._v(" "),
    _vm.isLoggedIn
      ? _c("div", { staticClass: "pr-2" }, [
          _c(
            "a",
            {
              staticClass: "navbar-logo logout",
              attrs: { title: "Terminar Sessão" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.$emit("signOut")
                }
              }
            },
            [_c("img", { attrs: { src: require("@/images/logout.svg") } })]
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "flex-grow-1 d-flex justify-content-center" },
      [
        _c("a", { staticClass: "navbar-logo", attrs: { href: "#" } }, [
          _c("img", {
            attrs: { src: require("@/images/evollu_logo_black.svg") }
          })
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }