<template>
  <div>
    <!-- Header -->
    <div>
      <h2 class="title text-center">{{ $t('titles.assignment_validation_contact') }}</h2>
      <h4 class="sub-title mt-5 uppercase">{{ $t('subtitles.fill_the_following_fields') }}</h4>
    </div>

    <!-- Form -->
    <div class="d-flex flex-column mt-3">
      <div class="row">
        <div class="col-6 mt-2">
          <div class="form-group">
            <input
                v-model="form.data.first_name"
                type="text"
                class="form-control round-input"
                :placeholder="$t('forms.placeholders.first_name')"
                :class="{'is-invalid': form.errors.has('mother_name')||form.errors.has('first_name')}"
                @input="form.errors.clear('mother_name')"
                @focusout="onValidateMotherName"
                required
            >

            <div class="invalid-feedback" v-show="form.errors.has('mother_name')||form.errors.has('first_name')">
              {{ $t(form.errors.get('mother_name') || form.errors.get('first_name')) }}
            </div>
          </div>
        </div>

        <div class="col-6 mt-2">
          <div class="form-group">
            <input
                v-model="form.data.last_name"
                type="text"
                class="form-control round-input"
                :placeholder="$t('forms.placeholders.last_name')"
                :class="{'is-invalid': form.errors.has('mother_name')||form.errors.has('last_name')}"
                @input="form.errors.clear('mother_name')"
                @focusout="onValidateMotherName"
                required
            >

            <div class="invalid-feedback" v-show="form.errors.has('last_name')">
              {{ $t(form.errors.get('last_name')) }}
            </div>
          </div>
        </div>
      </div>

      <h4 class="sub-title mt-5 uppercase">{{ $t('subtitles.validate_or_contact') }}</h4>

      <div class="row ml-1">
        <button
            class="toggle-button col-2 mr-1"
            type="button"
            :class="{active: this.form.validation.phone.selected}"
            @click="onPhoneNumber">
          {{ $t('buttons.phone_number') }}
          <fade-transition :duration="200" mode="out-in">
            <i v-show="this.form.validation.phone.success" class="input-icon fas fa-check-circle"/>
          </fade-transition>
        </button>
        <button
            class="toggle-button col-2 ml-1"
            type="button"
            :class="{active: this.form.validation.email.selected}"
            @click="onEmail">
          {{ $t('buttons.email') }}
          <fade-transition :duration="200" mode="out-in">
            <i v-show="this.form.validation.email.success" class="input-icon fas fa-check-circle"/>
          </fade-transition>
        </button>
      </div>


      <div class="container-contact mt-2"
           v-if="this.form.validation.email.selected || this.form.validation.phone.selected">
        <div v-if="!this.form.validation[this.selectedType].success">
          <h4 v-if="this.form.validation.phone.selected" class="sub-title ml-3 mt-3">
            {{ $t('forms.insert_your_phone_number') }}
          </h4>
          <h4 v-else-if="this.form.validation.email.selected" class="sub-title ml-3 mt-3">
            {{ $t('forms.insert_your_email') }}
          </h4>
          <div class="row align-items-center">
            <div v-if="this.form.validation.phone.selected" class="col-3 ml-3 mt-3">
              <div class="form-group">
                <input
                    v-model="form.data.phone_number"
                    type="tel"
                    class="form-control round-input"
                    :placeholder="$t('forms.placeholders.phone_number')"
                    :class="{'is-invalid': form.errors.has('phone_number')}"
                    @input="onContactChanged"
                >
                <div class="invalid-feedback" v-show="form.errors.has('phone_number')">
                  {{ $t(form.errors.get('phone_number')) }}
                </div>
              </div>
            </div>
            <div v-else-if="this.form.validation.email.selected" class="col-3 ml-3 mt-3">
              <div class="form-group">
                <input
                    v-model="form.data.email_address"
                    type="email"
                    class="form-control round-input"
                    :placeholder="$t('forms.placeholders.email')"
                    :class="{'is-invalid': form.errors.has('email_address')}"
                    @input="onContactChanged"
                >
                <div class="invalid-feedback" v-show="form.errors.has('email_address')">
                  {{ $t(form.errors.get('email_address')) }}
                </div>
              </div>
            </div>
            <button
                v-show="!form.validation[selectedType].codeSent"
                class="button-rounded"
                type="button"
                :disabled="this.isLoading"
                @click="onSendValidationCode">
              {{ $t('buttons.send_validation_code') }}
            </button>
            <fade-transition :duration="200" mode="out-in">
              <div>
          <span
              v-if="this.form.validation[this.selectedType].codeSent"
              class="ml-2 bold"
              :class="{'error-color': this.form.errors.has('code_sent'), 'primary-color':!this.form.errors.has('code_sent')}">
            <i
                v-if="!this.form.errors.has('code_sent')"
                class="input-icon fas fa-check-circle"/>
            <i
                v-else
                class="input-icon fas fa-exclamation-circle"/>
            {{ $t(this.form.errors.get('code_sent') || this.codeSentMessage) }}
          </span>
                <span
                    v-if="(this.form.validation.phone.selected && this.form.validation.phone.codeSent) || this.form.validation.email.selected && this.form.validation.email.codeSent"
                    class="ml-2 mt-3">
            {{ $t('messages.if_dont_received_the_code') }}
            <a class="button-text" @click="onResendCode">{{ $t('messages.click_here') }}</a>
          </span>
              </div>
            </fade-transition>
          </div>

          <h4 v-if="this.form.validation.phone.selected" class="sub-title ml-3 mt-3">
            {{ $t('forms.insert_your_validation_phone') }}
          </h4>
          <h4 v-else-if="this.form.validation.email.selected" class="sub-title ml-3 mt-3">
            {{ $t('forms.insert_your_validation_email') }}
          </h4>
          <div class="row align-items-center">
            <div class="col-3 ml-3 mt-3">
              <div class="form-group">
                <input
                    v-model="form.validation[selectedType].code"
                    type="number"
                    pattern="\d{6}"
                    autocomplete="one-time-code"
                    class="form-control round-input"
                    :placeholder="$t('forms.placeholders.validation_code')"
                    :class="{'is-invalid': form.errors.has('access_code')}"
                    @input="form.errors.clear('access_code')"
                >
                <div v-show="form.errors.has('access_code')" class="invalid-feedback">
                  {{ $t(form.errors.get('access_code')) }}
                </div>
              </div>
            </div>
            <button
                v-show="form.validation[selectedType].codeSent"
                class="button-rounded"
                type="button"
                @click="onValidateCode">
              {{ $t('buttons.validate') }}
            </button>
          </div>
        </div>
        <div v-else class="ml-2 mb-2">
          <div v-if="this.form.validation.phone.success && this.form.validation.email.success">
            <h5 class="bold primary-color">
              <i class="input-icon fas fa-check-circle"/>
              {{ $t('messages.successfully_validated') }}
            </h5>
            <h4 class="sub-title">
              {{ $t(`messages.visit_the_results`) }}
            </h4>
          </div>
          <div v-else>
            <h5 class="bold primary-color">
              <i class="input-icon fas fa-check-circle"/>
              {{ $t(`messages.${this.selectedType}_successfully_validated`) }}
            </h5>
            <h4 class="sub-title ml-1 mt-3">
              {{ $t(`messages.do_you_want_validate_your_${this.selectedType}`) }}
            </h4>
            <div class="row ml-1 mt-4">
              <button
                  class="col-4 mr-1 button-green button-rounded"
                  type="button"
                  @click="onChangeSelectedType">
                {{ $t(`buttons.i_want_validate_${this.selectedType}`) }}
              </button>
              <button
                  class="col-4 mr-1 button-rounded"
                  type="button"
                  @click="onVisit">
                {{ $t(`buttons.i_want_visit_results`) }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 is-grouped mt-5 mb-1">
        <div class="d-flex justify-content-space-evenly control">
          <button class="button" @click="onCancel">{{ $t('buttons.cancel') }}</button>
          <button
              v-show="this.form.validation.phone.success && this.form.validation.email.success"
              class="button button-green"
              @click="onVisit">
            {{ $t('buttons.visit') }}
          </button>
        </div>
      </div>

      <div v-if="isLoading">
        <loading-animation></loading-animation>
      </div>
    </div>
  </div>
</template>
<script>
import Errors from '@/utils/StandardErrors.js'
import {ExternalAccessService} from '@/api/RanuService'
import FadeTransition from '@/components/Utils/FadeTransition.vue';

export default {
  components: {FadeTransition},
  props: {
    record: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      form: {
        data: {
          email_address: null,
          first_name: null,
          last_name: null,
          phone_number: null,
        },
        validation: {
          email: {
            data: null,
            code: null,
            codeSentMessage: null,
            resent: false,
            selected: false,
            success: false
          },
          motherName: null,
          phone: {
            data: null,
            code: null,
            codeSentMessage: null,
            resent: false,
            selected: false,
            success: false
          },
        },
        errors: new Errors()
      },
      isLoading: false,
    }
  },
  computed: {
    selectedType: function () {
      let type;
      if (this.form.validation.phone.selected) {
        type = 'phone';
      } else if (this.form.validation.email.selected) {
        type = 'email';
      } else {
        return null;
      }
      return type;
    },
    codeSentMessage: function () {
      let key;
      if (this.form.errors.has('code_sent')) {
        key = this.form.errors.get('code_sent');
      } else {
        key = 'messages.code_sent'
      }
      return key;
    }
  },
  methods: {
    getAccessCodesIds: function () {
      const ids = [];
      if (this.form.validation.email.data) {
        this.form.validation.email.data.code = this.form.validation.email.code;
        ids.push(this.form.validation.email.data);
      }
      if (this.form.validation.phone.data) {
        this.form.validation.phone.data.code = this.form.validation.phone.code;
        ids.push(this.form.validation.phone.data);
      }
      return ids;
    },
    onCancel: function () {
      this.$emit('cancel');
    },
    onChangeSelectedType: function () {
      if (this.form.validation.phone.selected) {
        return this.onEmail();
      }
      if (this.form.validation.email.selected) {
        return this.onPhoneNumber();
      }
    },
    onContactChanged: function () {
      this.form.errors.clear(this.selectedType);
      this.form.validation[this.selectedType].codeSent = false;
      this.form.validation[this.selectedType].success = false;
      this.form.validation[this.selectedType].data = null;
    },
    onEmail: function () {
      this.form.validation.phone.selected = false;
      this.form.validation.email.selected = true;
    },
    onPhoneNumber: function () {
      this.form.validation.email.selected = false;
      this.form.validation.phone.selected = true;
    },
    onResendCode: function () {
      this.isLoading = true;
      ExternalAccessService.resendCode(this.record.id, this.form.validation[this.selectedType].data.id)
          .then(sent => {
            this.form.validation[this.selectedType].resent = sent;
          })
          .catch(error => {
            this.form.errors.set('code_sent', 'errors.something_went_wrong')
          })
          .finally(() => {
            this.isLoading = false
          });
    },
    onSendValidationCode: function () {
      this.isLoading = true;
      this.form.errors.clear();

      const data = {
        first_name: this.form.data.first_name,
        last_name: this.form.data.last_name
      };

      if (this.form.validation.email.selected) {
        data.email_address = this.form.data.email_address;
      } else if (this.form.validation.phone.selected) {
        data.phone_number = this.form.data.phone_number;
      }

      ExternalAccessService.addAuthorizationContact(this.record.id, data, false)
          .then((response) => {
            if (response.status === 201) {
              const type = response.data.authorization_contact.type;
              this.form.validation[type].codeSent = true;
              this.form.validation[type].success = response.data.validated_at !== null && response.data.authorization_contact.validated_at !== null;
              this.form.validation[type].data = response.data;
            }
          })
          .catch(error => {
            if (error.response && error.response.status === 422) {
              this.form.errors.record(error.response.data.errors, 'errors.')
            } else if (error.response && error.response.status === 403) {
              this.form.errors.set('mother_name', 'errors.mother_names_do_not_match')
            } else {
              this.form.errors.set('code_sent', 'errors.something_went_wrong')
            }
          })
          .finally(() => {
            this.isLoading = false
          })
    },
    onValidateCode: function () {
      this.isLoading = true;
      this.form.errors.clear();

      ExternalAccessService.validateCode(this.record.id, this.form.validation[this.selectedType].data.id, this.form.validation[this.selectedType].code)
          .then((response) => {
            if (!response) {
              return;
            }
            if (response.status === 202) {
              this.form.validation[this.selectedType].success = true;
            }
          })
          .catch(error => {
            error = error.response || error;
            if (error && (error.status === 422 || error.errors)) {
              error = error.data || error;
              this.form.errors.record(error.errors, 'errors.')
            } else if (error && error.status === 403) {
              this.form.errors.set('access_code', 'errors.access_code_invalid')
            } else {
              this.form.errors.set('access_code', 'errors.something_went_wrong')
            }
          })
          .finally(() => {
            this.isLoading = false
          })
    },
    onValidateMotherName: function () {
      this.isLoading = true;
      this.form.errors.clear();

      const motherName = {
        first_name: this.form.data.first_name,
        last_name: this.form.data.last_name,
      }

      ExternalAccessService.addAuthorizationContact(this.record.id, motherName, true)
          .then((response) => {
            if (!response) {
              return;
            }
            if (response.status === 202) {
              this.form.validation.motherName = response.data;
            }
          })
          .catch(error => {
            console.error(error)
            if (error.response && error.response.status === 422) {
              this.form.errors.record(error.response.data.errors, 'errors.')
            } else if (error.response && error.response.status === 403) {
              this.form.errors.set('mother_name', 'errors.mother_names_do_not_match')
            } else {
              this.form.errors.set('mother_name', 'errors.something_went_wrong')
              this.form.errors.set('phone_number', 'errors.something_went_wrong')
              this.form.errors.set('email_address', 'errors.something_went_wrong')
            }
          })
          .finally(() => {
            this.isLoading = false
          })
    },
    onVisit: function () {
      this.form.validation[this.selectedType].data.code = this.form.validation[this.selectedType].code;
      this.$emit('access_codes_verified', {accessCodes: this.getAccessCodesIds()});
    },
  }
}
</script>
<style scoped>
.form {
  width: 50%;
  margin: 50px auto;
}

h2.title {
  text-transform: uppercase;
}

div.form-group > input {
  padding-left: 22px;
}

.align-items-center {
  align-items: center;
}

.bold {
  font-weight: bold;
}

.toggle-button > i {
  margin-top: 0;
}
</style>
