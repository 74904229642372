<template>
  <component :is="type" 
    :tag="tag"
    enter-active-class="fadeIn"
    leave-active-class="fadeOut"
    move-class="fade-move"
    v-bind="$attrs"
    v-on="hooks"
  >
      <slot></slot>
  </component> 
</template>
<script>
export default {
  props: {
    duration: {
      type: Number,
      default: 150
    },
    group: {
      type: Boolean,
      default: false
    },
    tag: {
      type: String,
      default: 'div'
    }
  },
  computed: {
    type: function () {
      return this.group ? 'transition-group' : 'transition'
    },
    hooks: function () {
      return {
        beforeEnter: this.setDuration,
        afterEnter: this.cleanUpDuration,
        beforeLeave: this.setDuration,
        afterLeave: this.cleanUpDuration,
        leave: this.setAbsolutePosition,
        ...this.$listeners
      }
    }
  },
  methods: {
    setDuration: function (el) {
      el.style.animationDuration = `${this.duration}ms`
    },
    cleanUpDuration: function (el) {
      el.style.animationDuration = ''
    },
    setAbsolutePosition: function (el) {
      if (this.group) {
        el.style.position = 'absolute'
      }
    }
  }
}
</script>
<style>
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fadeIn {
  animation-name: fadeIn;
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.fadeOut {
  animation-name: fadeOut;
}
.fade-move {
  transition: transform 0.3s ease-out;
}
</style>
