export const AUTHORIZATION_CODE_PAGE = 'authorization_code_page';
export const AUTHORIZATION_PAGE = 'authorization_page';
export const CHOOSE_AUTHORIZATION_SIGNUP = 'choose_authorization_signup';
export const LOADING_PAGE = 'loading';
export const MAIN_PAGE = CHOOSE_AUTHORIZATION_SIGNUP;
export const RESULTS_PAGE = 'results_page';
export const SIGNUP_RECOVER_PAGE1 = 'signup_recover_page1';
export const SIGNUP_RECOVER_PAGE2 = 'signup_recover_page2';

export const UPDATE_CONTACTS = 'update_contacts_page'
