var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "text-center" }, [
      _c("h2", { staticClass: "title title-primary-color" }, [
        _vm._v(_vm._s(_vm.$t("titles.project_title")))
      ])
    ]),
    _vm._v(" "),
    _c(
      "form",
      {
        staticClass: "d-flex flex-column align-items-center",
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.submit($event)
          }
        }
      },
      [
        _c("div", { staticClass: "col-12 col-md-8 col-lg-6" }, [
          !_vm.routeParams.phone
            ? _c(
                "div",
                { staticClass: "form-group mt-3 has-search" },
                [
                  _vm._m(0),
                  _vm._v(" "),
                  _c("TheMask", {
                    staticClass: "form-control round-input",
                    class: { "is-invalid": _vm.form.errors.has("birthdate") },
                    attrs: {
                      id: "birthdate",
                      type: "text",
                      masked: true,
                      mask: "##/##/####",
                      pattern: "[0-9]{2}/[0-9]{2}/[0-9]{4}",
                      placeholder: "Data de Nascimento (dd-mm-aaaa)",
                      required: ""
                    },
                    on: {
                      focus: function($event) {
                        return _vm.form.errors.clear("birthdate")
                      }
                    },
                    model: {
                      value: _vm.form.data.birthdate,
                      callback: function($$v) {
                        _vm.$set(_vm.form.data, "birthdate", $$v)
                      },
                      expression: "form.data.birthdate"
                    }
                  }),
                  _vm._v(" "),
                  _vm.form.errors.has("birthdate")
                    ? _c("div", { staticClass: "invalid-feedback" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t(_vm.form.errors.get("birthdate"))) +
                            "\n        "
                        )
                      ])
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-12 col-md-8 col-lg-6 mt-2" }, [
          _c("div", { staticClass: "form-group has-search" }, [
            _vm._m(1),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.data.process_id,
                  expression: "form.data.process_id"
                }
              ],
              staticClass: "form-control round-input",
              class: { "is-invalid": _vm.form.errors.has("process_id") },
              attrs: {
                type: "text",
                placeholder: _vm.$t("forms.placeholders.process_id"),
                required: ""
              },
              domProps: { value: _vm.form.data.process_id },
              on: {
                focus: function($event) {
                  return _vm.form.errors.clear("process_id")
                },
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form.data, "process_id", $event.target.value)
                }
              }
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.form.errors.has("process_id"),
                    expression: "form.errors.has('process_id')"
                  }
                ],
                staticClass: "invalid-feedback"
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t(_vm.form.errors.get("process_id"))) +
                    "\n        "
                )
              ]
            )
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "col-12 col-md-8 col-lg-6 field is-grouped mt-5 mb-1"
          },
          [
            _c(
              "div",
              { staticClass: "d-flex justify-content-space-evenly control" },
              [
                _c(
                  "button",
                  {
                    staticClass: "button",
                    attrs: { type: "button" },
                    on: { click: _vm.onGoBack }
                  },
                  [_vm._v(_vm._s(_vm.$t("buttons.back")))]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "button button-green",
                    attrs: { type: "submit" }
                  },
                  [_vm._v(_vm._s(_vm.$t("buttons.next")))]
                )
              ]
            )
          ]
        ),
        _vm._v(" "),
        _vm.isLoading ? _c("div", [_c("loading-animation")], 1) : _vm._e()
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "form-control-feedback" }, [
      _c("i", { staticClass: "input-icon fas fa-calendar-alt" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "form-control-feedback" }, [
      _c("i", { staticClass: "input-icon far fa-address-card" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }