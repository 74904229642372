import ApiService from './ApiService'
import moment from 'moment';

console.dir(process.env)
export const ExternalAccessService = {
    baseURL: process.env.RANU_API,

    async addAuthorizationContact(recordId, data, validateBefore = true) {
        if (!recordId) {
            throw new Error('Cannot retrieve latest exam result without an recordId');
        }

        if (!this.isMotherNameFilled(data, validateBefore)) {
            return false;
        }

        data = {
            ...data,
            record_id: recordId
        }

        return await ApiService.post(`${this.baseURL}/v2/external/access/add-authorization-contact`, data);
    },

    async authorization(data) {
        if (!data) {
            throw new Error('Cannot retrieve send empty data')
        }

        data = {
            ...data,
            birthdate: moment(data.birthdate, 'DD/MM/YYYY').format('YYYY-MM-DD')
        };
        const response = await ApiService.post(`${this.baseURL}/v2/external/authorization`, data)
        return response.data;
    },

    async getLatestExamResult(record, accessCodes, data = {}) {
        if (!record) {
            throw new Error('Cannot retrieve latest exam result without an record')
        }

        if (!accessCodes || accessCodes.length === 0) {
            throw new Error('Cannot retrieve latest exam result without an accessCodes')
        }

        data.access_codes = accessCodes.map(item => item.id);
        const response = await ApiService.post(`${this.baseURL}/v2/external/access/${record.id}/latest-exam-result`, data)
        return response.data.data;
    },

    async resendCode(recordId, accessCodeId) {
        if (!recordId || !accessCodeId) {
            throw new Error('Cannot resend code without a recordId or accessCodeId');
        }

        try {
            const response = await ApiService.post(`${this.baseURL}/v2/external/access/${recordId}/resend-code/${accessCodeId}`, null);
            return response.status === 200;
        } catch (e) {
            return false;
        }
    },

    async signup(data) {
        const response = await ApiService.post(`${this.baseURL}/v2/external/signup`, data)
        return response.data.data;
    },

    async validateCode(recordId, accessCodeId, code) {
        if (!recordId || !accessCodeId) {
            throw new Error('Cannot resend code without a recordId or accessCodeId');
        }
        if (!(code && code.trim() && code.length === 6)) {
            throw {
                errors: {
                    access_code: ['access code invalid ']
                }
            }
        }

        return await ApiService.post(`${this.baseURL}/v2/external/access/${recordId}/validate-code/${accessCodeId}`, {access_code: code});
    },

    isMotherNameFilled(data, validateBefore = true) {
        if (data.first_name) {
            data.first_name = data.first_name.trim();
        }
        if (data.last_name) {
            data.last_name = data.last_name.trim();
        }

        return !validateBefore || (data.first_name && data.last_name);
    },

    updateContacts(accessId, accessCode, data = {}) {
        if (!accessId) {
            throw new Error('Cannot retrieve latest exam result without an accessId')
        }

        if (!accessCode) {
            throw new Error('Cannot retrieve latest exam result without an accessCode')
        }

        data.access_code = accessCode

        return new Promise((resolve, reject) => {
            return ApiService.post(`${this.baseURL}/v2/external/access/${accessId}/update-contacts`, data)
                .then(response => resolve(response.data.data))
                .catch(errors => reject(errors))
        })
    },
}
