<template>
<!-- Just an image -->
<nav class="navbar navbar-light d-flex">
  <div class="flex-grow-1 d-flex justify-content-center">
    <a class="navbar-logo" href="#">
      <img src="@/images/evollu_logo_black.svg">
    </a>
  </div>
  <div v-if="isLoggedIn" class="pr-2">
    <a class="navbar-logo logout" @click.prevent="$emit('signOut')" title="Terminar Sessão">
      <img src="@/images/logout.svg">
    </a>
  </div>
</nav>
</template>
<script>
export default {
  props: {
    isLoggedIn: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>
