<template>
  <div class="container pt-xs-1 pt-3 pb-5">
    <div class="text-center">
      <h5 class="sub-title">{{$t('subtitles.results_page')}}</h5>
    </div>
    <div v-if="hasExamResult">
      <!-- Result Header Section -->
      <div class="text-center mt-3">
        <div class="subtitle-1">{{examResult.record.name}}</div>
        <div class="subtitle-1" v-if="examResult.record && examResult.record.mother">Mãe: {{examResult.record.mother}}</div>
        <div class="subtitle-1" v-if="examResult.record && examResult.record.father">Pai: {{examResult.record.father}}</div>
        <div class="d-block subtitle-1">{{formattedDatetime(examResult.record.birthdate)}}</div>
      </div>

      <div class="container">
        <!-- Result Main Section -->
        <div class="d-flex justify-content-center mt-4">
          <div class="d-flex flex-column justify-content-center align-items-center">
            <div class="icon-wrapper mt-5 mr-4">
              <img class="icon-result" :src="parseEarResultToImage(examResult.result_left)" alt="">
            </div>
            <div class="icon-result-wrapper icon-result-left">
              <span class="icon-result-label">Esquerda</span>
              <span class="icon-result-label-small" v-if="examResult.result_left.id !== 5">({{examResult.result_left.name}})</span>
            </div>
          </div>
          <div>
            <img class="main-icon" src="@/images/newborn.svg" alt="">
          </div>
          <div class="d-flex flex-column justify-content-center align-items-center">
            <div class="icon-wrapper mt-5 ml-4">
              <img class="icon-result" :src="parseEarResultToImage(examResult.result_right)" alt="">
            </div>
            <div class="icon-result-wrapper icon-result-right" style="margin-left: 1.7rem;">
              <span class="icon-result-label">Direita</span>
              <span class="icon-result-label-small" v-if="examResult.result_right.id !== 5">({{examResult.result_right.name}})</span>
            </div>
          </div>
        </div>
      </div>

      <!-- Result Last Section -->
      <div class="d-flex flex-column justify-content-xs-center justify-content-sm-start align-items-center mt-4">
        <div>
          <span style="font-weight: normal; font-size: 1rem;" class="icon-result-label">Data do teste: {{formattedDatetime(examResult.performed_at)}}</span>
        </div>

        <div class="sub-title mt-2" v-if="!hasPassedExam || examResult.record.risk_factor">
          <div style="color: #97999b;" class="d-flex flex-column justify-content-center align-items-center">
            <span v-if="examResult.record.risk_factor">
              Vai ser contactado para consulta de Otorrinolaringologia, fator de risco.
            </span>
            <span v-else-if="!hasPassedExam">Se até aos 3 meses não for chamado deve dirigir-se ao serviço de ORL do Hospital onde realizou o RANU</span>
          </div>
        </div>
      </div>
    </div>
    <div v-else clasS="text-center">
      <div>
        <img class="main-icon" src="@/images/newborn.svg" alt="">
      </div>
      <p class="py-3">{{$t('messages.exam_results_not_ready')}}</p>
    </div>



  </div>
</template>
<script>
export default {
  props: {
    examResult: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      passedExamResults: [1, 8]
    }
  },
  computed: {

  },
  methods: {
    parseEarResultToImage: function (result) {
      if (this.passedExamResults.includes(result.id)) {
        return require('@/images/ic_success.svg')
      }
      return require('@/images/ic_refer.svg')
    },
    formattedDatetime: function (datetime) {
      return this.moment(datetime, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY')
    }
  },
  computed: {
    hasExamResult: function () {
      return this.examResult !== null && this.examResult !== undefined
    },
    hasPassedExam: function () {
      if (this.hasExamResult) {
        let passedExams = [...this.passedExamResults, 5]
        return passedExams.includes(this.examResult.result_right.id) && passedExams.includes(this.examResult.result_left.id)
      }
      return false
    }
  }
}
</script>

<style scoped>
.icon-wrapper {
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
}

.icon-result {
  width: 75px;
  height: 75px;
}

.icon-result-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 0.5rem;
}

.icon-result-label {
  text-transform: uppercase;
  font-weight: bold;
  color: #97999b;
  font-size: 0.9rem;
}

.icon-result-label-small {
  text-transform: uppercase;
  font-weight: bold;
  color: #97999b;
  font-size: 0.7rem;
}

.main-icon {
  width: 250px;
  height: 250px;
}

.icon-result-right {
  margin-left: 1.7rem;
}

.icon-result-left {
  margin-right: 1.7rem;
}

@media only screen and (max-width: 718px) {
  .main-icon {
    width: 40vw;
    height: 40vw;
  }

  .icon-result {
    width: 15vw;
    height: 15vw;
  }
}

@media only screen and (max-width: 350px) {
  .main-icon {
    width: 30vw;
    height: 30vw;
  }

  .icon-result {
    width: 10vw;
    height: 10vw;
  }

  .icon-wrapper {
    margin-top: 2rem !important;
  }

  .icon-result-label {
    font-size: 0.8rem;
  }

  .icon-result-right {
    margin-left: 1.2rem;
  }

  .icon-result-left {
    margin-right: 1.2rem;
  }
}
</style>