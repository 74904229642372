var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "form",
      {
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.updateContacts($event)
          }
        }
      },
      [
        _c("div", { staticClass: "text-center" }, [
          _c("h5", { staticClass: "sub-title" }, [
            _vm._v(_vm._s(_vm.$t("subtitles.control_contact")))
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              " " + _vm._s(_vm.$t("messages.control_contact_purpose")) + " "
            )
          ])
        ]),
        _vm._v(" "),
        _vm._l(_vm.form.data.contacts, function(contact, index) {
          return _c(
            "section",
            {
              key: index,
              staticClass: "mb-3 d-flex flex-column align-items-center"
            },
            [
              _c("div", { staticClass: "col-12 col-md-8 col-lg-6 mt-2" }, [
                _c("div", { staticClass: "form-group has-search" }, [
                  _vm._m(0, true),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.data.contacts[index].name,
                        expression: "form.data.contacts[index].name"
                      }
                    ],
                    staticClass: "form-control round-input",
                    class: {
                      "is-invalid":
                        _vm.form.errors.has("contacts." + index + ".name") ||
                        (!_vm.isEmpty(_vm.form.data.contacts[index].name) &&
                          !_vm.isValidName(_vm.form.data.contacts[index].name))
                    },
                    attrs: {
                      placeholder: _vm.$t("forms.placeholders.contact_name"),
                      required: ""
                    },
                    domProps: { value: _vm.form.data.contacts[index].name },
                    on: {
                      input: [
                        function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.form.data.contacts[index],
                            "name",
                            $event.target.value
                          )
                        },
                        function($event) {
                          return _vm.form.errors.clear(
                            "contacts." + index + ".name"
                          )
                        }
                      ]
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.form.errors.has(
                            "contacts." + index + ".name"
                          ),
                          expression:
                            "form.errors.has(`contacts.${index}.name`)"
                        }
                      ],
                      staticClass: "invalid-feedback"
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$t(
                              _vm.form.errors.get("contacts." + index + ".name")
                            )
                          ) +
                          "\n          "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            !_vm.isEmpty(_vm.form.data.contacts[index].name) &&
                            !_vm.isValidName(
                              _vm.form.data.contacts[index].name
                            ),
                          expression:
                            "!isEmpty(form.data.contacts[index].name) && !isValidName(form.data.contacts[index].name)"
                        }
                      ],
                      staticClass: "invalid-feedback"
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("errors.contact_name_is_invalid")) +
                          "\n          "
                      )
                    ]
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12 col-md-8 col-lg-6 mt-2" }, [
                _c("div", { staticClass: "form-group has-search" }, [
                  _vm._m(1, true),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.data.contacts[index].email,
                        expression: "form.data.contacts[index].email"
                      }
                    ],
                    staticClass: "form-control round-input",
                    class: {
                      "is-invalid":
                        _vm.form.errors.has("contacts." + index + ".email") ||
                        (!_vm.isEmpty(_vm.form.data.contacts[index].email) &&
                          !_vm.isValidEmail(
                            _vm.form.data.contacts[index].email
                          ))
                    },
                    attrs: {
                      placeholder: _vm.$t("forms.placeholders.contact_email"),
                      required: ""
                    },
                    domProps: { value: _vm.form.data.contacts[index].email },
                    on: {
                      input: [
                        function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.form.data.contacts[index],
                            "email",
                            $event.target.value
                          )
                        },
                        function($event) {
                          return _vm.form.errors.clear(
                            "contacts." + index + ".email"
                          )
                        }
                      ]
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.form.errors.has(
                            "contacts." + index + ".email"
                          ),
                          expression:
                            "form.errors.has(`contacts.${index}.email`)"
                        }
                      ],
                      staticClass: "invalid-feedback"
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$t(
                              _vm.form.errors.get(
                                "contacts." + index + ".email"
                              )
                            )
                          ) +
                          "\n          "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            !_vm.isEmpty(_vm.form.data.contacts[index].email) &&
                            !_vm.isValidEmail(
                              _vm.form.data.contacts[index].email
                            ),
                          expression:
                            "!isEmpty(form.data.contacts[index].email) && !isValidEmail(form.data.contacts[index].email)"
                        }
                      ],
                      staticClass: "invalid-feedback"
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("errors.contact_email_is_invalid")) +
                          "\n          "
                      )
                    ]
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12 col-md-8 col-lg-6 mt-2" }, [
                _c("div", { staticClass: "form-group has-search" }, [
                  _vm._m(2, true),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.data.contacts[index].phone,
                        expression: "form.data.contacts[index].phone"
                      }
                    ],
                    staticClass: "form-control round-input",
                    class: {
                      "is-invalid":
                        _vm.form.errors.has("contacts." + index + ".phone") ||
                        (!_vm.isEmpty(_vm.form.data.contacts[index].phone) &&
                          !_vm.isValidPhone(
                            _vm.form.data.contacts[index].phone
                          ))
                    },
                    attrs: {
                      placeholder: _vm.$t("forms.placeholders.contact_phone"),
                      required: ""
                    },
                    domProps: { value: _vm.form.data.contacts[index].phone },
                    on: {
                      input: [
                        function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.form.data.contacts[index],
                            "phone",
                            $event.target.value
                          )
                        },
                        function($event) {
                          return _vm.form.errors.clear(
                            "contacts." + index + ".phone"
                          )
                        }
                      ]
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.form.errors.has(
                            "contacts." + index + ".phone"
                          ),
                          expression:
                            "form.errors.has(`contacts.${index}.phone`)"
                        }
                      ],
                      staticClass: "invalid-feedback"
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$t(
                              _vm.form.errors.get(
                                "contacts." + index + ".phone"
                              )
                            )
                          ) +
                          "\n          "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            !_vm.isEmpty(_vm.form.data.contacts[index].phone) &&
                            !_vm.isValidPhone(
                              _vm.form.data.contacts[index].phone
                            ),
                          expression:
                            "!isEmpty(form.data.contacts[index].phone) && !isValidPhone(form.data.contacts[index].phone)"
                        }
                      ],
                      staticClass: "invalid-feedback"
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("errors.contact_phone_is_invalid")) +
                          "\n          "
                      )
                    ]
                  )
                ])
              ])
            ]
          )
        }),
        _vm._v(" "),
        _c("div", { staticClass: "field is-grouped mt-5 mb-1" }, [
          _c("div", { staticClass: "d-flex justify-content-center control" }, [
            _c(
              "button",
              {
                staticClass: "button button-green",
                attrs: { disabled: !_vm.isValidForm }
              },
              [_vm._v(_vm._s(_vm.$t("buttons.continue")))]
            )
          ])
        ]),
        _vm._v(" "),
        _vm.isLoading ? _c("div", [_c("loading-animation")], 1) : _vm._e()
      ],
      2
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "form-control-feedback" }, [
      _c("i", { staticClass: "input-icon fas fa-user" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "form-control-feedback" }, [
      _c("i", { staticClass: "input-icon fas fa-at" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "form-control-feedback" }, [
      _c("i", { staticClass: "input-icon fas fa-phone-alt" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }