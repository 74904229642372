var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "text-center" }, [
      _c("h2", { staticClass: "title title-primary-color" }, [
        _vm._v(_vm._s(_vm.$t("titles.project_title")))
      ]),
      _vm._v(" "),
      _c("h4", { staticClass: "sub-title mt-5" }, [
        _vm._v(_vm._s(_vm.$t("subtitles.choose_option")))
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "buttons-holder" }, [
      _c(
        "button",
        {
          staticClass: "button button-green button-big",
          on: { click: _vm.onFirstAccess }
        },
        [_vm._v(_vm._s(_vm.$t("buttons.first_access")))]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "button button-big",
          on: { click: _vm.onHaveAccountAlready }
        },
        [_vm._v(_vm._s(_vm.$t("buttons.have_account_already")))]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }