<template>
  <div>
    <form v-on:submit.prevent="updateContacts" >
      <div class="text-center">
        <h5 class="sub-title">{{$t('subtitles.control_contact')}}</h5>
        <p> {{$t('messages.control_contact_purpose')}} </p>
      </div>
      <section class="mb-3 d-flex flex-column align-items-center" v-for="(contact, index) in form.data.contacts" :key="index">
        <!-- Input for Name -->
        <div class="col-12 col-md-8 col-lg-6 mt-2">
          <div class="form-group has-search">
            <span class="form-control-feedback">
              <i class="input-icon fas fa-user"></i>
            </span>
            <input
              v-model="form.data.contacts[index].name"
              :placeholder="$t('forms.placeholders.contact_name')"
              class="form-control round-input"
              :class="{'is-invalid': form.errors.has(`contacts.${index}.name`) || (!isEmpty(form.data.contacts[index].name) && !isValidName(form.data.contacts[index].name))}"
              @input="form.errors.clear(`contacts.${index}.name`)"
              required
              >

            <div class="invalid-feedback" v-show="form.errors.has(`contacts.${index}.name`)">
              {{$t(form.errors.get(`contacts.${index}.name`))}}
            </div>

            <div class="invalid-feedback" v-show="!isEmpty(form.data.contacts[index].name) && !isValidName(form.data.contacts[index].name)">
              {{$t('errors.contact_name_is_invalid')}}
            </div>
          </div>
        </div>

        <!-- Input for Email -->
        <div class="col-12 col-md-8 col-lg-6 mt-2">
          <div class="form-group has-search">
            <span class="form-control-feedback">
              <i class="input-icon fas fa-at"></i>
            </span>
            <input
              v-model="form.data.contacts[index].email"
              :placeholder="$t('forms.placeholders.contact_email')"
              class="form-control round-input"
              :class="{'is-invalid': form.errors.has(`contacts.${index}.email`) || (!isEmpty(form.data.contacts[index].email) && !isValidEmail(form.data.contacts[index].email))}"
              @input="form.errors.clear(`contacts.${index}.email`)"
              required
              >

            <div class="invalid-feedback" v-show="form.errors.has(`contacts.${index}.email`)">
              {{$t(form.errors.get(`contacts.${index}.email`))}}
            </div>

            <div class="invalid-feedback" v-show="!isEmpty(form.data.contacts[index].email) && !isValidEmail(form.data.contacts[index].email)">
              {{$t('errors.contact_email_is_invalid')}}
            </div>
          </div>
        </div>

        <!-- Input for Phone -->
        <div class="col-12 col-md-8 col-lg-6 mt-2">
          <div class="form-group has-search">
            <span class="form-control-feedback">
              <i class="input-icon fas fa-phone-alt"></i>
            </span>
            <input
              v-model="form.data.contacts[index].phone"
              :placeholder="$t('forms.placeholders.contact_phone')"
              class="form-control round-input"
              :class="{'is-invalid': form.errors.has(`contacts.${index}.phone`) || (!isEmpty(form.data.contacts[index].phone) && !isValidPhone(form.data.contacts[index].phone))}"
              @input="form.errors.clear(`contacts.${index}.phone`)"
              required
            >

            <div class="invalid-feedback" v-show="form.errors.has(`contacts.${index}.phone`)">
              {{$t(form.errors.get(`contacts.${index}.phone`))}}
            </div>

            <div class="invalid-feedback" v-show="!isEmpty(form.data.contacts[index].phone) && !isValidPhone(form.data.contacts[index].phone)">
              {{$t('errors.contact_phone_is_invalid')}}
            </div>
          </div>
        </div>

      </section>

      <div class="field is-grouped mt-5 mb-1">
        <div class="d-flex justify-content-center control">
          <button :disabled="!isValidForm" class="button button-green">{{ $t('buttons.continue') }}</button>
        </div>
      </div>

      <div v-if="isLoading">
        <loading-animation></loading-animation>
      </div>
    </form>
  </div>
</template>
<script>
import Errors from '@/utils/StandardErrors.js'
import { ExternalAccessService } from '@/api/RanuService'
export default {
  props: {
    accessAttempt: {
      type: Object,
      required: true
    },
    accessCode: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      form: {
        data: {
          contacts: [
            {
              type: 'control',
              name: null,
              email: null,
              phone: null
            }
          ]
        },
        errors: new Errors()
      },
      isLoading: false
    }
  },
  computed: {
    isValidForm: function () {
      return this.form.data.contacts.every(contact => {
        return this.isValidName(contact.name) && this.isValidEmail(contact.email) && this.isValidPhone(contact.phone)
      })
    }
  },
  methods: {
    updateContacts: function () {
      if (this.isValidForm && !this.isLoading) {
        this.isLoading = true
        ExternalAccessService.updateContacts(this.accessAttempt.id, this.accessCode.code, this.form.data)
          .then(response => {
            this.$emit('contacts-updated')
          })
          .catch(error => {
            if (error.response && error.response.status === 403) {
              this.form.errors.set('generic_error', 'errors.access_code_invalid')
            } else if (error.response && error.response.status === 422) {
              // Endpoint allows multiple contacts
              this.form.errors.record(error.response.data.errors, 'errors.')
            } else {
              this.form.errors.set('generic_error', 'errors.something_went_wrong')
            }
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    },
    isEmpty: function (value) {
      return value === null || value === undefined || (typeof value === 'string' && value.length === 0)
    },
    isValidName: function (value) {
      return typeof value === 'string' && value.length > 3
    },
    isValidEmail: function (value) {
      return typeof value === 'string' && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
    },
    isValidPhone: function (value) {
      return typeof value === 'string' && value.length >= 9
    }
  }
}
</script>
<style scoped>
.form {
    width: 50%;
    margin: 50px auto;
}
</style>