<template>
  <div>
    <navbar :isLoggedIn="isLoggedIn" @signOut="onLogout"></navbar>

    <div class="container pt-xs-1 pt-3">
      <div class="content mt-5">
        <fade-transition :duration="200" mode="out-in">
          <choose-signin-signup
              v-if="currentPage === pages.CHOOSE_AUTHORIZATION_SIGNUP"
              @first-access="onFirstAccess"
              @have-an-account-already="onHaveAnAccountAlready"
          >
          </choose-signin-signup>

          <signup-recover-page1
              v-if="currentPage === pages.SIGNUP_RECOVER_PAGE1"
              :routeParams="routeParams"
              @signup_completed="onSignupCompleted"
              @go-back="onGoBack(pages.SIGNUP_RECOVER_PAGE1)"
          >
          </signup-recover-page1>

          <signup-recover-page2
              v-if="currentPage === pages.SIGNUP_RECOVER_PAGE2"
              :record="record"
              @access_codes_verified="onAuthorizationCompleted"
              @cancel="onGoBack(pages.SIGNUP_RECOVER_PAGE2)"
          >
          </signup-recover-page2>

          <authorization
              v-if="currentPage === pages.AUTHORIZATION_PAGE"
              :routeParams="routeParams"
              @on-code-sent="onCodeSent"
              @on-recover-account="onRecoverAccount"
          >
          </authorization>

          <authorization-code
              v-if="currentPage === pages.AUTHORIZATION_CODE_PAGE"
              :record="record"
              :access-code="accessCodes[0]"
              @go-back="onGoBack()"
              @authorization-completed="onAuthorizationCompleted"
          >
          </authorization-code>

          <results-page
              v-if="currentPage === pages.RESULTS_PAGE"
              :examResult="examResult"
          >
          </results-page>

          <update-contacts-page
              v-if="currentPage === pages.UPDATE_CONTACTS"
              :accessAttempt="accessAttempt"
              :accessCode="accessCode"
              @contacts-updated="onContactsUpdated"
          >
          </update-contacts-page>
        </fade-transition>
      </div>
    </div>
  </div>
</template>
<script>
import * as pages from '@/pages/configs'
import Authorization from '@/pages/Authorization.vue';
import AuthorizationCode from '@/pages/AuthorizationCode.vue';
import ChooseSigninSignup from '@/pages/ChooseAuthorizationSignup.vue'
import FadeTransition from '@/components/Utils/FadeTransition.vue'
import LocalStorageHelper from '@/utils/LocalStorageHelper'
import ResultsPage from '@/pages/ResultsPage.vue'
import SignupRecoverPage1 from '@/pages/SignupRecoverPage1.vue';
import SignupRecoverPage2 from '@/pages/SignupRecoverPage2.vue';
import UpdateContactsPage from '@/pages/UpdateContactsPage.vue'
import {ACCESS_CODES, RECORD} from '@/utils/LocalStorageKeys'
import {ExternalAccessService} from '@/api/RanuService'

const validParams = ['process_id']

export default {
  components: {
    Authorization,
    AuthorizationCode,
    ChooseSigninSignup,
    FadeTransition,
    ResultsPage,
    SignupRecoverPage1,
    SignupRecoverPage2,
    UpdateContactsPage,
  },
  data: function () {
    return {
      record: undefined,
      accessCodes: [],
      examResult: undefined,
      pages: pages,
      signin: true,
      navigation: [],
    }
  },
  created: function () {
    this.loadSavedCredentials()
        .then(hadSavedCredentials => {
          if (hadSavedCredentials && this.examResult !== undefined) {
            this.navigate(pages.RESULTS_PAGE);
          } else {
            this.navigate(pages.MAIN_PAGE);
          }
        })
        .catch(errors => {
          this.navigate(pages.MAIN_PAGE);
        })
  },
  methods: {
    forgetSavedCredentials: function () {
      LocalStorageHelper.clear(RECORD)
      LocalStorageHelper.clear(ACCESS_CODES)
    },
    loadLatestExamResult: function () {
      return new Promise((resolve, reject) => {
        if (this.record && this.accessCodes && this.accessCodes.length > 0) {
          ExternalAccessService.getLatestExamResult(this.record, this.accessCodes)
              .then(examResult => {
                this.examResult = examResult
                resolve()
              })
              .catch(errors => {
                console.log(errors)
                reject(errors)
              })
        }
      })
    },
    loadSavedCredentials: function () {
      return new Promise((resolve, reject) => {
        const record = LocalStorageHelper.get(RECORD)
        const accessCodes = LocalStorageHelper.get(ACCESS_CODES)
        if (record === null || accessCodes === null || accessCodes.length === 0) {
          resolve(false)
        } else if (accessCodes.every(item => this.moment().isAfter(this.$options.filters.localTime(item.valid_until)))) {
          this.reset()
          resolve(false)
        } else {
          this.record = record
          this.accessCodes = accessCodes

          this.loadLatestExamResult()
              .then(() => resolve(true))
              .catch(errors => {
                this.reset()
                reject(errors)
              })
        }
      })
    },
    navigate: function (page) {
      if (this.navigation.length !== 0 && this.navigation[this.navigation.length - 1] === pages.LOADING_PAGE) {
        this.navigation.pop();
      }
      this.navigation.push(page);
    },
    onAuthorizationCompleted: function (payload) {
      if (payload.accessCodes) {
        this.accessCodes = payload.accessCodes;
        this.saveCredentialsInLocalStorage(this.record, this.accessCodes)
        this.loadLatestExamResult()
            .then(() => this.navigate(pages.RESULTS_PAGE))
            .catch(errors => {
              // TODO: handle errors
            })
      }
    },
    onCodeSent: function (payload) {
      this.record = payload.record;
      this.accessCodes = [payload.access_code];
      this.navigate(pages.AUTHORIZATION_CODE_PAGE)
    },
    onContactsUpdated: function () {
      this.navigate(pages.RESULTS_PAGE);
    },
    onFirstAccess: function () {
      this.navigate(pages.SIGNUP_RECOVER_PAGE1);
    },
    onGoBack: function () {
      if (this.navigation.length === 1) {
        return;
      }
      this.navigation.pop();
    },
    onHaveAnAccountAlready: function () {
      this.navigate(pages.AUTHORIZATION_PAGE);
    },
    onLogout: function () {
      this.reset()
      this.navigate(pages.MAIN_PAGE);
    },
    onRecoverAccount: function () {
      this.navigate(pages.SIGNUP_RECOVER_PAGE1);
    },
    onSignupCompleted: function (payload) {
      if (payload && payload.id) {
        this.record = payload;
        this.navigate(pages.SIGNUP_RECOVER_PAGE2);
      }
    },
    reset: function () {
      this.record = undefined
      this.accessCodes = undefined
      this.examResult = undefined
      this.forgetSavedCredentials()
      this.navigation = [];
    },
    saveCredentialsInLocalStorage: function (record, accessCodes) {
      LocalStorageHelper.set(RECORD, record);
      LocalStorageHelper.set(ACCESS_CODES, accessCodes);
    },
  },
  computed: {
    currentPage: function () {
      if (this.navigation.length === 0) {
        return pages.LOADING_PAGE;
      }
      return this.navigation[this.navigation.length - 1];
    },
    isLoggedIn: function () {
      return this.record && this.accessCodes && this.accessCodes.length > 0 && this.accessCodes.every(item => item.code);
    },
    routeParams: function () {
      const params = {}
      const queryParameters = new URLSearchParams(window.location.search)

      for (const [key, value] of queryParameters.entries()) {
        if (validParams.find(validParam => validParam === key)) {
          params[key] = value;
        }
      }

      return params
    },
  }
}
</script>
