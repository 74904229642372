var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", [
      _c("h2", { staticClass: "title text-center" }, [
        _vm._v(_vm._s(_vm.$t("titles.assignment_validation_contact")))
      ]),
      _vm._v(" "),
      _c("h4", { staticClass: "sub-title mt-5 uppercase" }, [
        _vm._v(_vm._s(_vm.$t("subtitles.fill_the_following_fields")))
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "d-flex flex-column mt-3" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-6 mt-2" }, [
          _c("div", { staticClass: "form-group" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.data.first_name,
                  expression: "form.data.first_name"
                }
              ],
              staticClass: "form-control round-input",
              class: {
                "is-invalid":
                  _vm.form.errors.has("mother_name") ||
                  _vm.form.errors.has("first_name")
              },
              attrs: {
                type: "text",
                placeholder: _vm.$t("forms.placeholders.first_name"),
                required: ""
              },
              domProps: { value: _vm.form.data.first_name },
              on: {
                input: [
                  function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.form.data, "first_name", $event.target.value)
                  },
                  function($event) {
                    return _vm.form.errors.clear("mother_name")
                  }
                ],
                focusout: _vm.onValidateMotherName
              }
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.form.errors.has("mother_name") ||
                      _vm.form.errors.has("first_name"),
                    expression:
                      "form.errors.has('mother_name')||form.errors.has('first_name')"
                  }
                ],
                staticClass: "invalid-feedback"
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      _vm.$t(
                        _vm.form.errors.get("mother_name") ||
                          _vm.form.errors.get("first_name")
                      )
                    ) +
                    "\n          "
                )
              ]
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-6 mt-2" }, [
          _c("div", { staticClass: "form-group" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.data.last_name,
                  expression: "form.data.last_name"
                }
              ],
              staticClass: "form-control round-input",
              class: {
                "is-invalid":
                  _vm.form.errors.has("mother_name") ||
                  _vm.form.errors.has("last_name")
              },
              attrs: {
                type: "text",
                placeholder: _vm.$t("forms.placeholders.last_name"),
                required: ""
              },
              domProps: { value: _vm.form.data.last_name },
              on: {
                input: [
                  function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.form.data, "last_name", $event.target.value)
                  },
                  function($event) {
                    return _vm.form.errors.clear("mother_name")
                  }
                ],
                focusout: _vm.onValidateMotherName
              }
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.form.errors.has("last_name"),
                    expression: "form.errors.has('last_name')"
                  }
                ],
                staticClass: "invalid-feedback"
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t(_vm.form.errors.get("last_name"))) +
                    "\n          "
                )
              ]
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("h4", { staticClass: "sub-title mt-5 uppercase" }, [
        _vm._v(_vm._s(_vm.$t("subtitles.validate_or_contact")))
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row ml-1" }, [
        _c(
          "button",
          {
            staticClass: "toggle-button col-2 mr-1",
            class: { active: this.form.validation.phone.selected },
            attrs: { type: "button" },
            on: { click: _vm.onPhoneNumber }
          },
          [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t("buttons.phone_number")) +
                "\n        "
            ),
            _c(
              "fade-transition",
              { attrs: { duration: 200, mode: "out-in" } },
              [
                _c("i", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: this.form.validation.phone.success,
                      expression: "this.form.validation.phone.success"
                    }
                  ],
                  staticClass: "input-icon fas fa-check-circle"
                })
              ]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "toggle-button col-2 ml-1",
            class: { active: this.form.validation.email.selected },
            attrs: { type: "button" },
            on: { click: _vm.onEmail }
          },
          [
            _vm._v(
              "\n        " + _vm._s(_vm.$t("buttons.email")) + "\n        "
            ),
            _c(
              "fade-transition",
              { attrs: { duration: 200, mode: "out-in" } },
              [
                _c("i", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: this.form.validation.email.success,
                      expression: "this.form.validation.email.success"
                    }
                  ],
                  staticClass: "input-icon fas fa-check-circle"
                })
              ]
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      this.form.validation.email.selected || this.form.validation.phone.selected
        ? _c("div", { staticClass: "container-contact mt-2" }, [
            !this.form.validation[this.selectedType].success
              ? _c("div", [
                  this.form.validation.phone.selected
                    ? _c("h4", { staticClass: "sub-title ml-3 mt-3" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("forms.insert_your_phone_number")) +
                            "\n        "
                        )
                      ])
                    : this.form.validation.email.selected
                    ? _c("h4", { staticClass: "sub-title ml-3 mt-3" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("forms.insert_your_email")) +
                            "\n        "
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "row align-items-center" },
                    [
                      this.form.validation.phone.selected
                        ? _c("div", { staticClass: "col-3 ml-3 mt-3" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.form.data.phone_number,
                                    expression: "form.data.phone_number"
                                  }
                                ],
                                staticClass: "form-control round-input",
                                class: {
                                  "is-invalid": _vm.form.errors.has(
                                    "phone_number"
                                  )
                                },
                                attrs: {
                                  type: "tel",
                                  placeholder: _vm.$t(
                                    "forms.placeholders.phone_number"
                                  )
                                },
                                domProps: { value: _vm.form.data.phone_number },
                                on: {
                                  input: [
                                    function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.form.data,
                                        "phone_number",
                                        $event.target.value
                                      )
                                    },
                                    _vm.onContactChanged
                                  ]
                                }
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.form.errors.has(
                                        "phone_number"
                                      ),
                                      expression:
                                        "form.errors.has('phone_number')"
                                    }
                                  ],
                                  staticClass: "invalid-feedback"
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.$t(
                                          _vm.form.errors.get("phone_number")
                                        )
                                      ) +
                                      "\n              "
                                  )
                                ]
                              )
                            ])
                          ])
                        : this.form.validation.email.selected
                        ? _c("div", { staticClass: "col-3 ml-3 mt-3" }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.form.data.email_address,
                                    expression: "form.data.email_address"
                                  }
                                ],
                                staticClass: "form-control round-input",
                                class: {
                                  "is-invalid": _vm.form.errors.has(
                                    "email_address"
                                  )
                                },
                                attrs: {
                                  type: "email",
                                  placeholder: _vm.$t(
                                    "forms.placeholders.email"
                                  )
                                },
                                domProps: {
                                  value: _vm.form.data.email_address
                                },
                                on: {
                                  input: [
                                    function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.form.data,
                                        "email_address",
                                        $event.target.value
                                      )
                                    },
                                    _vm.onContactChanged
                                  ]
                                }
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.form.errors.has(
                                        "email_address"
                                      ),
                                      expression:
                                        "form.errors.has('email_address')"
                                    }
                                  ],
                                  staticClass: "invalid-feedback"
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.$t(
                                          _vm.form.errors.get("email_address")
                                        )
                                      ) +
                                      "\n              "
                                  )
                                ]
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.form.validation[_vm.selectedType]
                                .codeSent,
                              expression:
                                "!form.validation[selectedType].codeSent"
                            }
                          ],
                          staticClass: "button-rounded",
                          attrs: { type: "button", disabled: this.isLoading },
                          on: { click: _vm.onSendValidationCode }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("buttons.send_validation_code")) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "fade-transition",
                        { attrs: { duration: 200, mode: "out-in" } },
                        [
                          _c("div", [
                            this.form.validation[this.selectedType].codeSent
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "ml-2 bold",
                                    class: {
                                      "error-color": this.form.errors.has(
                                        "code_sent"
                                      ),
                                      "primary-color": !this.form.errors.has(
                                        "code_sent"
                                      )
                                    }
                                  },
                                  [
                                    !this.form.errors.has("code_sent")
                                      ? _c("i", {
                                          staticClass:
                                            "input-icon fas fa-check-circle"
                                        })
                                      : _c("i", {
                                          staticClass:
                                            "input-icon fas fa-exclamation-circle"
                                        }),
                                    _vm._v(
                                      "\n          " +
                                        _vm._s(
                                          _vm.$t(
                                            this.form.errors.get("code_sent") ||
                                              this.codeSentMessage
                                          )
                                        ) +
                                        "\n        "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            (this.form.validation.phone.selected &&
                              this.form.validation.phone.codeSent) ||
                            (this.form.validation.email.selected &&
                              this.form.validation.email.codeSent)
                              ? _c("span", { staticClass: "ml-2 mt-3" }, [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(
                                        _vm.$t(
                                          "messages.if_dont_received_the_code"
                                        )
                                      ) +
                                      "\n          "
                                  ),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "button-text",
                                      on: { click: _vm.onResendCode }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("messages.click_here"))
                                      )
                                    ]
                                  )
                                ])
                              : _vm._e()
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  this.form.validation.phone.selected
                    ? _c("h4", { staticClass: "sub-title ml-3 mt-3" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.$t("forms.insert_your_validation_phone")
                            ) +
                            "\n        "
                        )
                      ])
                    : this.form.validation.email.selected
                    ? _c("h4", { staticClass: "sub-title ml-3 mt-3" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.$t("forms.insert_your_validation_email")
                            ) +
                            "\n        "
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "row align-items-center" }, [
                    _c("div", { staticClass: "col-3 ml-3 mt-3" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.validation[_vm.selectedType].code,
                              expression: "form.validation[selectedType].code"
                            }
                          ],
                          staticClass: "form-control round-input",
                          class: {
                            "is-invalid": _vm.form.errors.has("access_code")
                          },
                          attrs: {
                            type: "number",
                            pattern: "\\d{6}",
                            autocomplete: "one-time-code",
                            placeholder: _vm.$t(
                              "forms.placeholders.validation_code"
                            )
                          },
                          domProps: {
                            value: _vm.form.validation[_vm.selectedType].code
                          },
                          on: {
                            input: [
                              function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.form.validation[_vm.selectedType],
                                  "code",
                                  $event.target.value
                                )
                              },
                              function($event) {
                                return _vm.form.errors.clear("access_code")
                              }
                            ]
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.form.errors.has("access_code"),
                                expression: "form.errors.has('access_code')"
                              }
                            ],
                            staticClass: "invalid-feedback"
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.$t(_vm.form.errors.get("access_code"))
                                ) +
                                "\n              "
                            )
                          ]
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.form.validation[_vm.selectedType].codeSent,
                            expression: "form.validation[selectedType].codeSent"
                          }
                        ],
                        staticClass: "button-rounded",
                        attrs: { type: "button" },
                        on: { click: _vm.onValidateCode }
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("buttons.validate")) +
                            "\n          "
                        )
                      ]
                    )
                  ])
                ])
              : _c("div", { staticClass: "ml-2 mb-2" }, [
                  this.form.validation.phone.success &&
                  this.form.validation.email.success
                    ? _c("div", [
                        _c("h5", { staticClass: "bold primary-color" }, [
                          _c("i", {
                            staticClass: "input-icon fas fa-check-circle"
                          }),
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$t("messages.successfully_validated")
                              ) +
                              "\n          "
                          )
                        ]),
                        _vm._v(" "),
                        _c("h4", { staticClass: "sub-title" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("messages.visit_the_results")) +
                              "\n          "
                          )
                        ])
                      ])
                    : _c("div", [
                        _c("h5", { staticClass: "bold primary-color" }, [
                          _c("i", {
                            staticClass: "input-icon fas fa-check-circle"
                          }),
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$t(
                                  "messages." +
                                    this.selectedType +
                                    "_successfully_validated"
                                )
                              ) +
                              "\n          "
                          )
                        ]),
                        _vm._v(" "),
                        _c("h4", { staticClass: "sub-title ml-1 mt-3" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$t(
                                  "messages.do_you_want_validate_your_" +
                                    this.selectedType
                                )
                              ) +
                              "\n          "
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "row ml-1 mt-4" }, [
                          _c(
                            "button",
                            {
                              staticClass:
                                "col-4 mr-1 button-green button-rounded",
                              attrs: { type: "button" },
                              on: { click: _vm.onChangeSelectedType }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$t(
                                      "buttons.i_want_validate_" +
                                        this.selectedType
                                    )
                                  ) +
                                  "\n            "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "col-4 mr-1 button-rounded",
                              attrs: { type: "button" },
                              on: { click: _vm.onVisit }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$t("buttons.i_want_visit_results")
                                  ) +
                                  "\n            "
                              )
                            ]
                          )
                        ])
                      ])
                ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 is-grouped mt-5 mb-1" }, [
        _c(
          "div",
          { staticClass: "d-flex justify-content-space-evenly control" },
          [
            _c(
              "button",
              { staticClass: "button", on: { click: _vm.onCancel } },
              [_vm._v(_vm._s(_vm.$t("buttons.cancel")))]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      this.form.validation.phone.success &&
                      this.form.validation.email.success,
                    expression:
                      "this.form.validation.phone.success && this.form.validation.email.success"
                  }
                ],
                staticClass: "button button-green",
                on: { click: _vm.onVisit }
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("buttons.visit")) +
                    "\n        "
                )
              ]
            )
          ]
        )
      ]),
      _vm._v(" "),
      _vm.isLoading ? _c("div", [_c("loading-animation")], 1) : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }