import Vue from 'vue'
import moment from 'moment'
/**
 * Capitalize first letter of string (ex: today is a GOOD day. => Today is a good day.)
 * @param string value
 * @return string
 */
Vue.filter('capitalize', (value) => {
  return value ? value.toString().toLowerCase().split(' ').map(s => s.charAt(0).toUpperCase() + s.substring(1)).join(' ') : ''
})

Vue.filter('upperCase', (value) => {
  return value ? value.toString().toUpperCase() : ''
})
/**
 * Converts a date string to local timezone
 * @param string value
 * @param string format
 * @param string defaultValue
 * @param string locale
 * @return string
 */
Vue.filter('localTime', function (value, format = 'YYYY-MM-DD HH:mm:ss', defaultValue = '', locale = 'pt') {
  if (!value) {
    return defaultValue
  }
  moment.locale(locale)
  return moment.utc(value).local().format(format)
})
/**
 * Converts a date string to utc (used to manipulate date fields before sending it to server)
 * @param string value
 * @param string format
 * @param string defaultValue
 * @param string locale
 * @return string
 */
Vue.filter('utcTime', function (value, format = 'YYYY-MM-DD HH:mm:ss', defaultValue = null, locale = 'pt') {
  if (!value) {
    return defaultValue
  }
  moment.locale(locale)
  return moment(value).utc().format(format)
})

Vue.filter('isToday', function (value) {
  if (!value) {
    return false
  }
  return moment.utc(value).local().isSame(new Date(), 'day')
})

Vue.filter('readableDate', function (value, defaultValue = '') {
  if (value) {
    moment.locale(Vue.i18n.locale())
    return moment(value).from(moment())
  }
  return defaultValue
})

Vue.filter('formattedDateString', function (value, format = 'YYYY-MM-DD HH:mm:ss', defaultValue = '') {
  if (value) {
    return moment(value).format(format)
  }
  return defaultValue
})
