<template>
  <div>
    <!-- Header -->
    <div class="text-center">
      <h2 class="title uppercase">{{ $t('titles.login_validation') }}</h2>
      <span class="ml-2 mt-3" v-if="!this.form.success">
        {{ $t('messages.if_dont_received_the_code') }}
        <a class="button-text" @click="onResendCode">{{ $t('messages.click_here') }}</a>
      </span>
    </div>

    <!-- Form -->
    <div class="d-flex flex-column container-contact mt-3">
      <div v-if="!form.success">
        <h5 class="sub-title ml-3 mt-3">
          {{ $t('forms.insert_your_validation_code') }}
        </h5>
        <div class="row align-items-center">
          <div class="col-5 ml-3 mt-2">
            <div class="form-group">
              <input
                  v-model="form.data.code"
                  type="number"
                  pattern="\d{6}"
                  autocomplete="one-time-code"
                  class="form-control round-input"
                  :placeholder="$t('forms.placeholders.validation_code')"
                  :class="{'is-invalid': form.errors.has('access_code')}"
                  @input="form.errors.clear('access_code')"
              >
              <div v-show="form.errors.has('access_code')" class="invalid-feedback">
                {{ $t(form.errors.get('access_code')) }}
              </div>
            </div>
          </div>
          <button
              class="button-rounded"
              type="button"
              @click="onSendCode">
            {{ $t('buttons.validate') }}
          </button>
        </div>
      </div>
      <div v-else class="ml-3">
        <h5 class="bold primary-color">
          <i class="input-icon fas fa-check-circle"/>
          {{ $t('messages.successfully_validated') }}
        </h5>
        <h5 class="bold sub-title mt-3">
          {{ $t('forms.proceed') }}
        </h5>
      </div>
    </div>

    <div class="col-12 is-grouped mt-5 mb-1">
      <div class="d-flex justify-content-space-evenly control">
        <button class="button" @click="onCancel">{{ $t('buttons.cancel') }}</button>
        <button
            class="button button-green"
            @click="onVisit">
          {{ $t('buttons.visit') }}
        </button>
      </div>
    </div>

    <div v-if="isLoading">
      <loading-animation></loading-animation>
    </div>
  </div>
</template>

<script>
import Errors from '@/utils/StandardErrors.js'
import {ExternalAccessService} from '@/api/RanuService';
import {TheMask} from 'vue-the-mask';

export default {
  components: {
    TheMask
  },
  props: {
    record: {
      type: Object,
      required: true
    },
    accessCode: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      form: {
        data: {
          code: null,
        },
        codeResent: false,
        errors: new Errors(),
        success: false,
      },
      isLoading: false,
    }
  },
  methods: {
    onCancel: function () {
      this.$emit('go-back');
    },
    onResendCode: function () {
      this.isLoading = true;
      ExternalAccessService.resendCode(this.record.id, this.accessCode.id)
          .then(sent => {
            this.codeResent = sent;
          })
          .catch(error => {
            this.form.errors.set('code_sent', 'errors.something_went_wrong')
          })
          .finally(() => {
            this.isLoading = false
          });
    },
    onSendCode: function () {
      this.isLoading = true;
      ExternalAccessService.validateCode(this.record.id, this.accessCode.id, this.form.data.code)
          .then(response => {
            if (!response) {
              return;
            }
            if (response.status === 202) {
              this.form.success = true;
            }
          })
          .catch(error => {
            if (error && (error.status === 422 || error.errors)) {
              error = error.data || error;
              this.form.errors.record(error.errors, 'errors.')
            }
          })
          .finally(() => {
            this.isLoading = false
          })
    },
    onVisit: function () {
      if (!this.form.success) {
        return;
      }
      this.accessCode.code = this.form.data.code;
      this.$emit('authorization-completed', {accessCodes: [this.accessCode]});
    },
  }
}
</script>
<style scoped>
.bold {
  font-weight: bold;
}
</style>
