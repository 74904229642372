<template>
  <div>
    <div class="text-center">
      <h2 class="title title-primary-color">{{ $t('titles.project_title') }}</h2>
      <h4 class="sub-title mt-5">{{ $t('subtitles.choose_option') }}</h4>
    </div>

    <div class="buttons-holder">
      <button class="button button-green button-big" @click="onFirstAccess">{{ $t('buttons.first_access') }}</button>
      <button class="button button-big" @click="onHaveAccountAlready">{{ $t('buttons.have_account_already') }}</button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'signin-signup',
  methods: {
    onFirstAccess: function () {
      this.$emit('first-access');
    },
    onHaveAccountAlready: function () {
      this.$emit('have-an-account-already');
    }
  }
}
</script>
<style scoped>
div.buttons-holder {
  width: 100%;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: flex-start;
  margin-top: 5.125rem;
}

.button {
  margin-left: 2.656rem;
  margin-right: 2.656rem;
}
</style>
