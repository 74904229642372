<template>
  <div>
    <!--    Header-->
    <div class="text-center">
      <h2 class="title title-primary-color">{{ $t('titles.project_title') }}</h2>
    </div>

    <!-- Form -->
    <form v-on:submit.prevent="submit" class="d-flex flex-column align-items-center">
      <div class="col-12 col-md-8 col-lg-6">
        <div class="form-group mt-3 has-search" v-if="!routeParams.phone">
          <span class="form-control-feedback">
            <i class="input-icon fas fa-calendar-alt"></i>
          </span>
          <TheMask
              id="birthdate"
              type="text"
              v-model="form.data.birthdate"
              :masked="true"
              class="form-control round-input"
              mask="##/##/####"
              pattern="[0-9]{2}/[0-9]{2}/[0-9]{4}"
              placeholder="Data de Nascimento (dd-mm-aaaa)"
              :class="{'is-invalid': form.errors.has('birthdate')}"
              @focus="form.errors.clear('birthdate')"
              required
          />
          <div class="invalid-feedback" v-if="form.errors.has('birthdate')">
            {{ $t(form.errors.get('birthdate')) }}
          </div>
        </div>
      </div>

      <div class="col-12 col-md-8 col-lg-6 mt-2">
        <div class="form-group has-search">
          <span class="form-control-feedback">
            <i class="input-icon far fa-address-card"></i>
          </span>
          <input
              v-model="form.data.process_id"
              type="text"
              class="form-control round-input"
              :placeholder="$t('forms.placeholders.process_id')"
              :class="{'is-invalid': form.errors.has('process_id')}"
              @focus="form.errors.clear('process_id')"
              required
          >

          <div class="invalid-feedback" v-show="form.errors.has('process_id')">
            {{ $t(form.errors.get('process_id')) }}
          </div>
        </div>
      </div>

      <div class="col-12 col-md-8 col-lg-6 field is-grouped mt-5 mb-1">
        <div class="d-flex justify-content-space-evenly control">
          <button class="button" type="button" @click="onGoBack">{{ $t('buttons.back') }}</button>
          <button class="button button-green" type="submit">{{ $t('buttons.next') }}</button>
        </div>
      </div>

      <div v-if="isLoading">
        <loading-animation></loading-animation>
      </div>
    </form>
  </div>
</template>
<script>
import Errors from '@/utils/StandardErrors.js'
import {ExternalAccessService} from '@/api/RanuService'
import {TheMask} from 'vue-the-mask'

export default {
  components: {
    TheMask
  },
  props: {
    routeParams: {
      type: Object,
      required: true
    },
  },
  mounted: function () {
    if (this.routeParams.process_id) {
      this.form.data.process_id = this.routeParams.process_id;
    }
  },
  data() {
    return {
      form: {
        data: {
          process_id: null,
          birthdate: null,
        },
        errors: new Errors()
      },
      isLoading: false
    }
  },
  methods: {
    onGoBack: function () {
      this.$emit('go-back');
    },
    submit: function () {
      if (!this.isLoading) {
        this.isLoading = true
        this.form.errors.clear();
        let data = {
          ...this.form.data,
          birthdate: this.moment(this.form.data.birthdate, 'DD/MM/YYYY').set({
            hours: 0,
            minutes: 0,
            seconds: 0
          }).utc().format('YYYY-MM-DD HH:mm:ss')
        }
        ExternalAccessService.signup(data)
            .then(record => this.$emit('signup_completed', record))
            .catch((err) => {
              if (err.response.status === 422) {
                this.form.errors.record(err.response.data.errors, 'errors.')
              }
            }).finally(() => {
          this.isLoading = false
        })
      }
    },
  }
}
</script>
