import Vue from 'vue'
import { i18n } from './i18n'
import App from '@/components/App.vue'
import Navbar from '@/components/Navbar.vue'
import LoadingAnimation from '@/components/Utils/LoadingAnimation.vue'
import moment from 'moment'
import './styles/index.scss'
import './filters'

Vue.prototype.moment = moment


Vue.component('navbar', Navbar)
Vue.component('loading-animation', LoadingAnimation)

new Vue({
  el: '#container',
  i18n,
  render: h => h(App),
})