var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "text-center" }, [
      _c("h2", { staticClass: "title uppercase" }, [
        _vm._v(_vm._s(_vm.$t("titles.login_validation")))
      ]),
      _vm._v(" "),
      !this.form.success
        ? _c("span", { staticClass: "ml-2 mt-3" }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.$t("messages.if_dont_received_the_code")) +
                "\n      "
            ),
            _c(
              "a",
              { staticClass: "button-text", on: { click: _vm.onResendCode } },
              [_vm._v(_vm._s(_vm.$t("messages.click_here")))]
            )
          ])
        : _vm._e()
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "d-flex flex-column container-contact mt-3" }, [
      !_vm.form.success
        ? _c("div", [
            _c("h5", { staticClass: "sub-title ml-3 mt-3" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("forms.insert_your_validation_code")) +
                  "\n      "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row align-items-center" }, [
              _c("div", { staticClass: "col-5 ml-3 mt-2" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.data.code,
                        expression: "form.data.code"
                      }
                    ],
                    staticClass: "form-control round-input",
                    class: { "is-invalid": _vm.form.errors.has("access_code") },
                    attrs: {
                      type: "number",
                      pattern: "\\d{6}",
                      autocomplete: "one-time-code",
                      placeholder: _vm.$t("forms.placeholders.validation_code")
                    },
                    domProps: { value: _vm.form.data.code },
                    on: {
                      input: [
                        function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.form.data, "code", $event.target.value)
                        },
                        function($event) {
                          return _vm.form.errors.clear("access_code")
                        }
                      ]
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.form.errors.has("access_code"),
                          expression: "form.errors.has('access_code')"
                        }
                      ],
                      staticClass: "invalid-feedback"
                    },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t(_vm.form.errors.get("access_code"))) +
                          "\n            "
                      )
                    ]
                  )
                ])
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "button-rounded",
                  attrs: { type: "button" },
                  on: { click: _vm.onSendCode }
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("buttons.validate")) +
                      "\n        "
                  )
                ]
              )
            ])
          ])
        : _c("div", { staticClass: "ml-3" }, [
            _c("h5", { staticClass: "bold primary-color" }, [
              _c("i", { staticClass: "input-icon fas fa-check-circle" }),
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("messages.successfully_validated")) +
                  "\n      "
              )
            ]),
            _vm._v(" "),
            _c("h5", { staticClass: "bold sub-title mt-3" }, [
              _vm._v(
                "\n        " + _vm._s(_vm.$t("forms.proceed")) + "\n      "
              )
            ])
          ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-12 is-grouped mt-5 mb-1" }, [
      _c(
        "div",
        { staticClass: "d-flex justify-content-space-evenly control" },
        [
          _c("button", { staticClass: "button", on: { click: _vm.onCancel } }, [
            _vm._v(_vm._s(_vm.$t("buttons.cancel")))
          ]),
          _vm._v(" "),
          _c(
            "button",
            { staticClass: "button button-green", on: { click: _vm.onVisit } },
            [
              _vm._v(
                "\n        " + _vm._s(_vm.$t("buttons.visit")) + "\n      "
              )
            ]
          )
        ]
      )
    ]),
    _vm._v(" "),
    _vm.isLoading ? _c("div", [_c("loading-animation")], 1) : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }