var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "text-center" }, [
      _c("h2", { staticClass: "title title-primary-color" }, [
        _vm._v(_vm._s(_vm.$t("titles.project_title")))
      ])
    ]),
    _vm._v(" "),
    _c(
      "form",
      {
        staticClass: "d-flex flex-column align-items-center",
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.submit($event)
          }
        }
      },
      [
        _c("div", { staticClass: "col-12 col-md-8 col-lg-6" }, [
          _c(
            "div",
            { staticClass: "form-group mt-3 has-search" },
            [
              _vm._m(0),
              _vm._v(" "),
              _c("TheMask", {
                staticClass: "form-control round-input",
                class: { "is-invalid": _vm.form.errors.has("birthdate") },
                attrs: {
                  id: "birthdate",
                  type: "text",
                  masked: true,
                  mask: "##/##/####",
                  pattern: "[0-9]{2}/[0-9]{2}/[0-9]{4}",
                  placeholder: "Data de Nascimento (dd-mm-aaaa)",
                  required: ""
                },
                on: {
                  input: function($event) {
                    return _vm.form.errors.clear("birthdate")
                  }
                },
                model: {
                  value: _vm.form.data.birthdate,
                  callback: function($$v) {
                    _vm.$set(_vm.form.data, "birthdate", $$v)
                  },
                  expression: "form.data.birthdate"
                }
              }),
              _vm._v(" "),
              _vm.form.errors.has("birthdate")
                ? _c("div", { staticClass: "invalid-feedback" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t(_vm.form.errors.get("birthdate"))) +
                        "\n        "
                    )
                  ])
                : _vm._e()
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-12 col-md-8 col-lg-6 mt-2" }, [
          _c("div", { staticClass: "form-group has-search" }, [
            _vm._m(1),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.data.process_id,
                  expression: "form.data.process_id"
                }
              ],
              staticClass: "form-control round-input",
              class: { "is-invalid": _vm.form.errors.has("process_id") },
              attrs: {
                type: "text",
                placeholder: _vm.$t("forms.placeholders.process_id"),
                required: ""
              },
              domProps: { value: _vm.form.data.process_id },
              on: {
                focus: function($event) {
                  return _vm.form.errors.clear("process_id")
                },
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form.data, "process_id", $event.target.value)
                }
              }
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.form.errors.has("process_id"),
                    expression: "form.errors.has('process_id')"
                  }
                ],
                staticClass: "invalid-feedback"
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t(_vm.form.errors.get("process_id"))) +
                    "\n        "
                )
              ]
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-12 col-md-8 col-lg-6 mt-2" }, [
          _c("div", { staticClass: "form-group has-search" }, [
            _vm._m(2),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.data.contact,
                  expression: "form.data.contact"
                }
              ],
              staticClass: "form-control round-input",
              class: { "is-invalid": _vm.form.errors.has("contact") },
              attrs: {
                type: "text",
                placeholder: _vm.$t("forms.placeholders.contact"),
                required: ""
              },
              domProps: { value: _vm.form.data.contact },
              on: {
                focus: function($event) {
                  return _vm.form.errors.clear("contact")
                },
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form.data, "contact", $event.target.value)
                }
              }
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.form.errors.has("contact"),
                    expression: "form.errors.has('contact')"
                  }
                ],
                staticClass: "invalid-feedback"
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t(_vm.form.errors.get("contact"))) +
                    "\n        "
                )
              ]
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-12 col-md-8 col-lg-6" }, [
          _c("p", { staticClass: "left" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t("messages.recover_access")) +
                "\n        "
            ),
            _c(
              "a",
              {
                staticClass: "button-text",
                class: { disabled: _vm.isLoading },
                on: { click: _vm.onRecoverAccount }
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("messages.click_here")) +
                    "\n        "
                )
              ]
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-12 col-md-8 col-lg-6 contents" }, [
          _c(
            "button",
            {
              staticClass: "button-rounded button-green",
              attrs: { type: "button", disabled: _vm.isLoading },
              on: { click: _vm.onSendCode }
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("buttons.send_validation_code")) +
                  "\n      "
              )
            ]
          )
        ]),
        _vm._v(" "),
        _vm.isLoading ? _c("div", [_c("loading-animation")], 1) : _vm._e()
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "form-control-feedback" }, [
      _c("i", { staticClass: "input-icon fas fa-calendar-alt" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "form-control-feedback" }, [
      _c("i", { staticClass: "input-icon far fa-address-card" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "form-control-feedback" }, [
      _c("i", { staticClass: "input-icon far fa-address-book" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }